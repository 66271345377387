import React from "react"
import Layout from "../../../components/layout";
import {getJobPreview, getJobs} from "../../../helpers/apiService";
import JobDetailComponent from "../../../components/jobDetailComponent";
import {findSimilarJobs} from "../../../helpers/utils";

class JobPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job: {},
            similarJobs: [],
            currentJobKey: 0,
            error: false,
            loading: true,
        }
    }

    setJob() {
        const state = this.props.location.state
        const jobKey = this.props.params.key
        if(state && state.job) {
            this.setState({job: state.job, loading: false, error: false, currentJobKey: jobKey})
        } else {
            getJobPreview(jobKey).then(response => {
                if(response.ok) {
                    return response.json()
                } else {
                    throw new Error()
                }
            })
            .then(response => {
                this.setState({loading: false, error: false, currentJobKey: jobKey, job: response}, () => {
                    this.setSimilarJobs()
                })
            })
            .catch(error => {
                this.setState({loading: false, error: true, currentJobKey: jobKey, job: {}})
            })
        }
    }

    setSimilarJobs() {
        getJobs().then(response => {
            if(response.ok) {
                return response.json()
            } else {
                throw new Error()
            }
        })
        .then(response => {
            const similarJobs = findSimilarJobs(this.state.job, response, 300)
            this.setState({similarJobs: similarJobs})
        })
        .catch(error => {
            console.log(error)
        })
    }

    componentDidMount() {
        this.setJob()
    }

    render() {
        const {job, loading, error, similarJobs} = this.state
        return (
            <Layout location={this.props.location} title="Job Preview">
                <div className="content">
                    <JobDetailComponent job={job} similarJobs={similarJobs} loading={loading} error={error} ></JobDetailComponent>
                </div>
            </Layout>
        )
    }
}

export default JobPreview